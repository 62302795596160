import { cloneDeep } from 'lodash';

import TableSection from './components/TableSection';
import { TableCheckBoxField, TableTextField } from './CustomTable.types';
import { RowData } from './utils';
import CheckBoxSection from '../common/CheckBoxSection';
import ConditionalDescriptionSection from '../common/ConditionalDescriptionSection';
import DescriptionSection from '../common/DescriptionSection';
import TextFieldSection from '../common/TextFieldSection';
import {
    ReportElementErrorType,
    ReportFieldDataContentType
} from '../CreateReport.types';
import ReportElementContainer from '../ReportElementContainer';

type TableReportProps = {
    item: ReportFieldDataContentType;
    selectedDashboardIds: any;
    reportElementError: ReportElementErrorType;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    handleError: (error: string, key: string, id: string) => void;
    deleteItem: (id: string | undefined) => void;
};

const TableReport = ({
    item,
    selectedDashboardIds,
    reportElementError,
    handleReportElementChange,
    handleError,
    deleteItem
}: TableReportProps) => {
    const id = item.dragAndDropId ?? '';

    const handleCheckBoxChange = (key: TableCheckBoxField, value: boolean) => {
        const updatedItem = cloneDeep(item);
        updatedItem!.reportElement!.configuration![key] = value;
        handleReportElementChange(updatedItem);
    };
    const handleTextChange = (key: TableTextField, value: string) => {
        const updatedItem = cloneDeep(item);
        updatedItem!.reportElement!.configuration![key] = value;
        handleReportElementChange(updatedItem);
    };
    const handleTableChange = (rowData: RowData[]) => {
        const updatedItem = cloneDeep(item);
        updatedItem!.reportElement!.configuration!.table = rowData;
        handleReportElementChange(updatedItem);
    };

    return (
        <ReportElementContainer
            deleteItem={deleteItem}
            item={item}
            selectedDashboardIds={selectedDashboardIds}
            title="Table"
        >
            <CheckBoxSection
                handleChange={value =>
                    handleCheckBoxChange(TableCheckBoxField.HIDE, value)
                }
                isChecked={item.reportElement?.configuration?.hideIfSumZero}
                title="Hide the table if sum count is zero"
            />
            <TextFieldSection
                handleChange={value =>
                    handleTextChange(TableTextField.TITLE, value)
                }
                handleError={error => {
                    handleError(error, TableTextField.TITLE, id);
                }}
                id={id}
                itemKey={TableTextField.TITLE}
                reportElementError={reportElementError}
                title="Title"
                value={item.reportElement?.configuration?.title || ''}
            />
            <DescriptionSection
                handleChange={value =>
                    handleTextChange(TableTextField.BEFORE, value)
                }
                handleError={error =>
                    handleError(error, TableTextField.BEFORE, id)
                }
                id={id}
                itemKey={TableTextField.BEFORE}
                reportElementError={reportElementError}
                title="Pre Description"
                value={item.reportElement?.configuration?.before || ''}
            />
            <TableSection
                handleError={error => handleError(error, 'table', id)}
                handleTableChange={handleTableChange}
                id={id}
                itemKey="table"
                reportElementError={reportElementError}
                rowData={item.reportElement?.configuration?.table || []}
            />
            <DescriptionSection
                handleChange={value =>
                    handleTextChange(TableTextField.AFTER, value)
                }
                handleError={error =>
                    handleError(error, TableTextField.AFTER, id)
                }
                id={id}
                itemKey={TableTextField.AFTER}
                reportElementError={reportElementError}
                title="Post Description"
                value={item.reportElement?.configuration?.after || ''}
            />
            <ConditionalDescriptionSection
                handleChange={value =>
                    handleTextChange(TableTextField.RECOMMENDATIONS, value)
                }
                handleCheckboxChange={value =>
                    handleCheckBoxChange(
                        TableCheckBoxField.RECOMMENDATIONS,
                        value
                    )
                }
                handleError={error =>
                    handleError(error, TableTextField.RECOMMENDATIONS, id)
                }
                id={id}
                isChecked={
                    item.reportElement?.configuration?.recommendationsCheckbox
                }
                itemKey={TableTextField.RECOMMENDATIONS}
                reportElementError={reportElementError}
                title="Recommendations"
                value={item.reportElement?.configuration?.recommendations || ''}
            />
            <CheckBoxSection
                handleChange={value =>
                    handleCheckBoxChange(
                        TableCheckBoxField.INCLUDE_IN_SUMMARY,
                        value
                    )
                }
                isChecked={item.reportElement?.configuration?.includeInSummary}
                title="Include in the table summary report element"
            />
        </ReportElementContainer>
    );
};

export default TableReport;
