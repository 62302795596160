import { DragDropLine } from 'src/assets/images';

import { NoDataComponent, NoDataText } from './CreateReport.style';

const NoData = () => (
    <NoDataComponent>
        <img alt="no-data" src={DragDropLine} />
        <NoDataText>
            Drag & drop report elements here to start generating report preview
        </NoDataText>
    </NoDataComponent>
);

export default NoData;
