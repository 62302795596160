export enum ASQAction {
    ADD = 'add',
    EDIT = 'edit',
    DELETE = 'delete'
}

export enum TableCheckBoxField {
    HIDE = 'hideIfSumZero',
    RECOMMENDATIONS = 'recommendationsCheckbox',
    INCLUDE_IN_SUMMARY = 'includeInSummary'
}

export enum TableTextField {
    TITLE = 'title',
    BEFORE = 'before',
    AFTER = 'after',
    RECOMMENDATIONS = 'recommendations'
}
