import { DOWNLOAD_JSON } from 'src/constants/LabelText';

import { MenuItemDataType, ReportletElementType, TableElementType } from './CreateReport.types';

export const ITEMS: ReportletElementType[] = [
    {
        id: 'segment_title',
        type: 'segment_title',
        label: 'Segment Title'
    },
    {
        id: 'segment_overview_title',
        type: 'segment_overview_title',
        label: 'Segment Overview Title'
    },
    {
        id: 'overview_text',
        type: 'overview_text',
        label: 'Overview Text'
    },
    {
        id: 'line_break',
        type: 'line_break',
        label: 'Segment Divider'
    },
    {
        id: 'page_break',
        type: 'page_break',
        label: 'Page Break'
    },
    {
        id: 'table',
        type: 'table',
        label: 'Table'
    }
];

export const menuItems: MenuItemDataType[] = [
    { value: 'save_as', label: 'Save As' },
    { value: 'download_json', label: DOWNLOAD_JSON }
];

export const defaultTableConfiguration: TableElementType = {
    type: 'table',
    title: '',
    table: [],
    before: '',
    after: '',
    recommendations: '',
    recommendationsCheckbox: false,
    hideIfSumZero: false,
    includeInSummary: false
};