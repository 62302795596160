import React, { Dispatch, SetStateAction } from 'react';

import { MenuItemProps, MultiSelect, TableNoDataIcon } from '@armis/armis-ui-library';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Card, CardContent, CardMedia, FormLabel, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FaArrowRight } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DASHBOARD, NO_DATA_FOUND, TENANT } from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import { getDashlets, getTenantDashlets } from 'src/services/api.service';
import { v4 as uuid } from 'uuid';

import { EllipsisTypography, StyledDiv, StyledField, StyledTypography } from './CreateReport.style';
import { DashletDataType, PagenumberType, ReportFieldsDataType, TenantDataType } from './CreateReport.types';
import { renderChartSvg } from './utils';

type DashletsPanelProps = {
    dashletData: DashletDataType,
    selectedTenantId: string,
    setSelectedTenantId: Dispatch<SetStateAction<string>>,
    tenantData: TenantDataType[],
    selectedDashboards: number[],
    setSelectedDashboards: Dispatch<SetStateAction<number[]>>,
    dashboardCount: string,
    dashboardList: MenuItemProps[],
    pageNumber: PagenumberType,
    setDashboardList: Dispatch<SetStateAction<MenuItemProps[]>>,
    reportFieldsData: ReportFieldsDataType,
    setReportFieldsData: Dispatch<SetStateAction<ReportFieldsDataType>>,
    setDashletData: Dispatch<SetStateAction<DashletDataType>>,
    expandedPanel: string | boolean,
    setIsLoading: (flag: boolean) => void,
    nextScroll: (type: string) => void
}

const DashletsPanel = ({
    dashletData,
    selectedTenantId,
    setSelectedTenantId,
    tenantData,
    selectedDashboards,
    setSelectedDashboards,
    dashboardCount,
    pageNumber,
    dashboardList,
    setDashboardList,
    reportFieldsData,
    setReportFieldsData,
    setDashletData,
    expandedPanel,
    setIsLoading,
    nextScroll
}: DashletsPanelProps ) => {
    const handleFilter = (e: SelectChangeEvent<unknown>) => {
        setSelectedTenantId(e.target.value as string);
    };
    const copyAllDashboards = () => {
        setIsLoading(true);

        getTenantDashlets(
            selectedTenantId,
            0,
            dashletData.totalElements || 10,
            selectedDashboards.join()
        )
            .then(res => {
                const copyData = res.data.content.map((dashlet: any) => ({
                    dragAndDropId: uuid(),
                    previewDragAndDropId: uuid(),
                    isReportlet: true,
                    reportlet: {
                        layout: 'LEFT',
                        recommendations: '',
                        recommendationsCheckbox: false,
                        benchmarkCheckbox: false,
                        benchmark: '',
                        hideSection: false,
                        reportDashlet: {
                            title: dashlet.title || '',
                            dashletDescription: '',
                            dashletJson: dashlet.dashletJson
                        }
                    }
                }));

                const copyReportFieldData = cloneDeep(reportFieldsData);

                copyReportFieldData.reportlet_data = [
                    ...copyReportFieldData.reportlet_data,
                    ...copyData
                ];

                setReportFieldsData(copyReportFieldData);
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => setIsLoading(false));
    };
    const setDashletDataonDashboardChange = async () => {
        setDashletData({} as DashletDataType);
        if (selectedTenantId !== 'All') {
            const tempDashboardList = [...dashboardList];

            tempDashboardList.forEach(dashboard => {
                if (
                    selectedDashboards.some((ele: any) => ele === dashboard.id)
                ) {
                    dashboard.checkStatus = 'checked';
                } else {
                    dashboard.checkStatus = 'unchecked';
                }
            });

            setDashboardList(tempDashboardList);

            await getTenantDashlets(
                selectedTenantId,
                pageNumber.dashletPageNumber,
                10,
                selectedDashboards.join()
            )
                .then(res => setDashletData(res.data))
                .catch(err => {
                    displayErrorMessage(err);
                });
        } else {
            getDashlets(pageNumber.dashletPageNumber, 10)
                .then(res => setDashletData(res.data))
                .catch(err => {
                    displayErrorMessage(err);
                });
        }
    };

    return (
        <StyledDiv
            id="scrollableDashletDiv"
            style={{
                height: '42vh',
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <InfiniteScroll
                className="infinite-scroll"
                dataLength={dashletData?.content?.length ?? 0}
                hasMore={
                    dashletData?.totalElements !== 0 &&
                    dashletData?.content?.length !==
                        dashletData?.totalElements
                }
                loader={<h4>Loading...</h4>}
                next={() => nextScroll('dashlets')}
                scrollableTarget="scrollableDashletDiv"
                style={{
                    overflow: 'hidden'
                }}
            >
                <Droppable
                    droppableId="dashlets"
                    isDropDisabled
                    type="report-items"
                >
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            // @ts-ignore
                            // isDraggingOver={snapshot.isDraggingOver}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <StyledField>
                                        <FormLabel htmlFor="dashboard-selection">
                                            {TENANT}
                                        </FormLabel>
                                        <Select
                                            defaultValue="All"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }
                                            }}
                                            onChange={e =>
                                                handleFilter(e)
                                            }
                                            style={{
                                                minWidth: 156,
                                                maxWidth: 156
                                            }}
                                            variant="outlined"
                                        >
                                            <MenuItem value="All">
                                                All
                                            </MenuItem>
                                            {tenantData.map(tenant => (
                                                <MenuItem
                                                    key={tenant.id}
                                                    value={tenant.id}
                                                >
                                                    {tenant.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledField>
                                </Grid>

                                <Grid item xs={6}>
                                    <StyledField>
                                        <FormLabel htmlFor="dashboard-selection">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                {DASHBOARD}
                                                <Tooltip
                                                    arrow
                                                    placement="bottom"
                                                    sx={{
                                                        '.MuiTooltip-tooltip':
                                                            {
                                                                marginTop:
                                                                    '4px !important'
                                                            }
                                                    }}
                                                    title={
                                                        selectedTenantId ===
                                                            'All' ||
                                                        selectedDashboards.length !==
                                                            1 ? null : (
                                                            <div className="tooltip-arrow-text">
                                                                Move All
                                                                Dashlets
                                                            </div>
                                                        )
                                                    }
                                                >
                                                    <IconButton
                                                        disabled={
                                                            selectedTenantId ===
                                                                'All' ||
                                                            selectedDashboards.length !==
                                                                1
                                                        }
                                                        onClick={
                                                            copyAllDashboards
                                                        }
                                                        style={{
                                                            marginLeft:
                                                                'auto',
                                                            backgroundColor:
                                                                selectedTenantId ===
                                                                    'All' ||
                                                                selectedDashboards.length !==
                                                                    1
                                                                    ? 'transparent'
                                                                    : ''
                                                        }}
                                                    >
                                                        <FaArrowRight
                                                            className="close"
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </FormLabel>
                                        <Select
                                            defaultValue="All Dashboards"
                                            disabled={
                                                selectedTenantId ===
                                                'All'
                                            }
                                            // fullWidth
                                            id="dashboard-selection"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }
                                            }}
                                            onClose={() =>
                                                setDashletDataonDashboardChange()
                                            }
                                            renderValue={() =>
                                                dashboardCount
                                            }
                                            style={{
                                                minWidth: 156,
                                                maxWidth: 156
                                            }}
                                            value={dashboardCount}
                                            variant="outlined"
                                        >
                                            <MultiSelect
                                                isAllSelected
                                                items={dashboardList}
                                                onSelectionChanged={selectedItems => {
                                                    setSelectedDashboards(
                                                        selectedItems.map(
                                                            (
                                                                element: any
                                                            ) =>
                                                                element.id
                                                        )
                                                    );
                                                }}
                                                showSelectAllOption
                                            />
                                        </Select>
                                    </StyledField>
                                </Grid>

                                {dashletData?.content?.length > 0 ? (
                                    <>
                                        <div />
                                        {dashletData?.content?.map(
                                            (
                                                dashlet,
                                                index: number
                                            ) => {
                                                const cardImage =
                                                    renderChartSvg(
                                                        dashlet
                                                            .dashletJson
                                                            .dashlet
                                                            .visualizationConfig
                                                            .type
                                                    );

                                                return (
                                                    <Draggable
                                                        key={dashlet.id}
                                                        draggableId={
                                                            dashlet.id
                                                        }
                                                        index={index}
                                                    >
                                                        {dragprovided => (
                                                            <Grid
                                                                item
                                                                xs={6}
                                                            >
                                                                <Card
                                                                    ref={
                                                                        dragprovided.innerRef
                                                                    }
                                                                    {...dragprovided.draggableProps}
                                                                    {...dragprovided.dragHandleProps}
                                                                    // @ts-ignore
                                                                    // isDragging={
                                                                    //     snapshot.isDragging
                                                                    // }
                                                                    // style={{
                                                                    //     zIndex: 10003
                                                                    // }}
                                                                    style={
                                                                        dragprovided
                                                                            .draggableProps
                                                                            .style
                                                                    }
                                                                >
                                                                    <CardMedia
                                                                        alt="Your image"
                                                                        component="img"
                                                                        height="80"
                                                                        image={
                                                                            cardImage
                                                                        }
                                                                        sx={{
                                                                            padding:
                                                                                '1em',
                                                                            objectFit:
                                                                                'contain',
                                                                            alignContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center'
                                                                        }}
                                                                    />
                                                                    <CardContent>
                                                                        <Tooltip
                                                                            arrow
                                                                            placement="bottom"
                                                                            sx={{
                                                                                '.MuiTooltip-tooltip':
                                                                                    {
                                                                                        marginTop:
                                                                                            '4px !important'
                                                                                    }
                                                                            }}
                                                                            title={
                                                                                <div className="tooltip-arrow-text">
                                                                                    {
                                                                                        dashlet.title
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <EllipsisTypography>
                                                                                {
                                                                                    dashlet.title
                                                                                }
                                                                            </EllipsisTypography>
                                                                        </Tooltip>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    expandedPanel === 'dashlets' && (
                                        <div
                                            style={{
                                                display: 'flex',

                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                marginLeft: '33%',
                                                marginTop: '8%'
                                            }}
                                        >
                                            <TableNoDataIcon
                                                style={{
                                                    height: '150px',
                                                    width: '150px'
                                                }}
                                            />
                                            <StyledTypography variant="h3">
                                                {NO_DATA_FOUND}
                                            </StyledTypography>
                                        </div>
                                    )
                                )}
                            </Grid>
                        </div>
                    )}
                </Droppable>
            </InfiniteScroll>
        </StyledDiv>
    );
};

export default DashletsPanel;