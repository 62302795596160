import React from 'react';

import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Card, CardContent, CardMedia, Grid, Tooltip } from '@mui/material';

import { EllipsisTypography, StyledDiv } from './CreateReport.style';
import { ITEMS } from './data';
import { renderChartSvg } from './utils';

const ReportElementsPanel = () => (
    <StyledDiv
        id="scrollableDashletDiv"
        style={{
            height: '42vh',
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'column'
        }}
    >
        <Droppable
            droppableId="report-elements"
            isDropDisabled
            type="report-items"
        >
            {provided => (
                <div
                    ref={provided.innerRef}
                    // @ts-ignore
                    // isDraggingOver={snapshot.isDraggingOver}
                >
                    <Grid container spacing={2}>
                        {ITEMS.map((element, index) => {
                            const cardImage = renderChartSvg(
                                element.type
                            );

                            return (
                                <Draggable
                                    key={element.id}
                                    draggableId={element.id}
                                    index={index}
                                >
                                    {dragProvided => (
                                        <Grid
                                            ref={
                                                dragProvided.innerRef
                                            }
                                            // isDragging={
                                            //     snapshot.isDragging
                                            // }
                                            item
                                            style={
                                                dragProvided
                                                    .draggableProps
                                                    .style
                                            }
                                            xs={6}
                                            {...dragProvided.draggableProps}
                                            {...dragProvided.dragHandleProps}
                                            // @ts-ignore
                                        >
                                            <Card>
                                                <CardMedia
                                                    alt="Your image"
                                                    component="img"
                                                    height="80"
                                                    image={
                                                        cardImage
                                                    }
                                                    sx={{
                                                        padding:
                                                            '1em',
                                                        objectFit:
                                                            'contain',
                                                        alignContent:
                                                            'center',
                                                        alignItems:
                                                            'center',
                                                        justifyContent:
                                                            'center'
                                                    }}
                                                />
                                                <CardContent>
                                                    <Tooltip
                                                        arrow
                                                        placement="bottom"
                                                        sx={{
                                                            '.MuiTooltip-tooltip':
                                                                {
                                                                    marginTop:
                                                                        '4px !important'
                                                                }
                                                        }}
                                                        title={
                                                            <div className="tooltip-arrow-text">
                                                                {
                                                                    element.label
                                                                }
                                                            </div>
                                                        }
                                                    >
                                                        <EllipsisTypography>
                                                            {
                                                                element.label
                                                            }
                                                        </EllipsisTypography>
                                                    </Tooltip>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )}
                                </Draggable>
                            );
                        })}
                    </Grid>
                </div>
            )}
        </Droppable>
    </StyledDiv>
  );

export default ReportElementsPanel;