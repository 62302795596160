import React, { Dispatch, SetStateAction } from 'react';

import { MenuItemProps, MultiSelect, TableNoDataIcon } from '@armis/armis-ui-library';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Card, CardContent, CardMedia, FormLabel, Grid, Select, Tooltip } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NO_DATA_FOUND, REPORT } from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import { getAllReportlets } from 'src/services/api.service';

import { EllipsisTypography, StyledDiv, StyledField, StyledTypography } from './CreateReport.style';
import { PagenumberType, ReportletDataType } from './CreateReport.types';
import { renderChartSvg } from './utils';

type ReportletsPanelProps = {
    reportletData: ReportletDataType,
    setReportletData: Dispatch<SetStateAction<ReportletDataType>>,
    pageNumber: PagenumberType,
    selectedReports: string[],
    setSelectedReports: Dispatch<SetStateAction<string[]>>,
    reportCount: string,
    reportList: MenuItemProps[],
    expandedPanel: string | boolean,
    nextScroll: (type: string) => void
}

const ReportletsPanel = ({
    reportletData, 
    setReportletData, 
    pageNumber, 
    selectedReports, 
    setSelectedReports, 
    reportCount, 
    reportList, 
    expandedPanel,  
    nextScroll
    }: ReportletsPanelProps ) => {
    const selectedReportletData = async () => {
        setReportletData({} as ReportletDataType);
        await getAllReportlets(
            pageNumber.reportletPageNumber,
            10,
            selectedReports.join()
        )
            .then(res => setReportletData(res.data))
            .catch(err => {
                displayErrorMessage(err);
            });
    };

    return (
        <StyledDiv
            id="scrollableReportletDiv"
            style={{
                height: '42vh',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <InfiniteScroll
                dataLength={reportletData?.content?.length ?? 0}
                hasMore={
                    reportletData?.totalElements !== 0 &&
                    reportletData?.content?.length !==
                        reportletData?.totalElements
                }
                loader={<h4>Loading...</h4>}
                next={() => nextScroll('reportlets')}
                scrollableTarget="scrollableReportletDiv"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                <Droppable
                    droppableId="reportlets"
                    isDropDisabled
                    type="report-items"
                >
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            // @ts-ignore
                            // isDraggingOver={snapshot.isDraggingOver}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <StyledField>
                                        <FormLabel htmlFor="dashboard-selection">
                                            {REPORT}
                                        </FormLabel>
                                        <Select
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }
                                            }}
                                            onClose={() =>
                                                selectedReportletData()
                                            }
                                            renderValue={() =>
                                                reportCount
                                            }
                                            style={{
                                                minWidth: 156,
                                                maxWidth: 156
                                            }}
                                            value={reportCount}
                                            variant="outlined"
                                        >
                                            <MultiSelect
                                                isAllSelected
                                                items={reportList}
                                                onSelectionChanged={selectedItems => {
                                                    setSelectedReports(
                                                        selectedItems.map(
                                                            element =>
                                                                element.id
                                                        )
                                                    );
                                                }}
                                                showSelectAllOption
                                            />
                                        </Select>
                                    </StyledField>
                                </Grid>
                                <Grid item xs={6} />

                                {reportletData?.content?.length > 0 ? (
                                    <>
                                        <div />
                                        {reportletData?.content?.map(
                                            (
                                                reportlet,
                                                index: number
                                            ) => {
                                                const cardImage =
                                                    renderChartSvg(
                                                        reportlet
                                                            .reportDashlet
                                                            .dashletJson
                                                            .dashlet
                                                            .visualizationConfig
                                                            .type
                                                    );

                                                return (
                                                    <Draggable
                                                        key={
                                                            reportlet.id
                                                        }
                                                        draggableId={
                                                            reportlet.id
                                                        }
                                                        index={index}
                                                    >
                                                        {dragProvided => (
                                                            <Grid
                                                                ref={
                                                                    dragProvided.innerRef
                                                                }
                                                                // isDragging={
                                                                //     snapshot.isDragging
                                                                // }
                                                                item
                                                                {...dragProvided.draggableProps}
                                                                {...dragProvided.dragHandleProps}
                                                                // @ts-ignore

                                                                style={
                                                                    dragProvided
                                                                        .draggableProps
                                                                        .style
                                                                }
                                                                xs={6}
                                                            >
                                                                <Card
                                                                // style={{
                                                                //     zIndex: 10003
                                                                // }}
                                                                >
                                                                    <CardMedia
                                                                        alt="Your image"
                                                                        component="img"
                                                                        height="80"
                                                                        image={
                                                                            cardImage
                                                                        }
                                                                        sx={{
                                                                            padding:
                                                                                '1em',
                                                                            objectFit:
                                                                                'contain',
                                                                            alignContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                            justifyContent:
                                                                                'center'
                                                                        }}
                                                                    />
                                                                    <CardContent>
                                                                        <Tooltip
                                                                            arrow
                                                                            placement="bottom"
                                                                            sx={{
                                                                                '.MuiTooltip-tooltip':
                                                                                    {
                                                                                        marginTop:
                                                                                            '4px !important'
                                                                                    }
                                                                            }}
                                                                            title={
                                                                                <div className="tooltip-arrow-text">
                                                                                    {
                                                                                        reportlet
                                                                                            .reportDashlet
                                                                                            .title
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <EllipsisTypography>
                                                                                {
                                                                                    reportlet
                                                                                        .reportDashlet
                                                                                        .title
                                                                                }
                                                                            </EllipsisTypography>
                                                                        </Tooltip>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    expandedPanel === 'reportlets' && (
                                        <div
                                            style={{
                                                display: 'flex',

                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                marginLeft: '33%',
                                                marginTop: '8%'
                                            }}
                                        >
                                            <TableNoDataIcon
                                                style={{
                                                    height: '150px',
                                                    width: '150px'
                                                }}
                                            />
                                            <StyledTypography variant="h3">
                                                {NO_DATA_FOUND}
                                            </StyledTypography>
                                        </div>
                                    )
                                )}
                            </Grid>
                        </div>
                    )}
                </Droppable>
            </InfiniteScroll>
        </StyledDiv>
    );
};

export default ReportletsPanel;