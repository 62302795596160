import { DraggableLocation } from '@hello-pangea/dnd';
import { has, mapValues, omit, some } from 'lodash';
import {
    columnchart,
    gaugechart,
    linechart,
    overviewText,
    piechart,
    segmentDivider,
    segmentOverviewTitle,
    segmentTitle,
    singlevaluechart,
    tablechart,
    PageBreak,
    TableIcon
} from 'src/assets/images';

import { ReportElementErrorType } from './CreateReport.types';

export const supportedImageTypes = ['image/png', 'image/jpeg'];

export const isFieldHavingError = (
    reportElementError: ReportElementErrorType,
    id: string | undefined,
    colName: string
) => {
    const colId = id ?? '';

    if (reportElementError[colId]) {
        const errorElement = reportElementError[colId].find(
            single => single.colName === colName
        );

        if (errorElement?.isPristine) return false;
        return errorElement?.error || false;
    }

    return false;
};

export const getFieldErrorMessage = (
    reportElementError: ReportElementErrorType,
    id: string | undefined,
    colName: string
) => {
    const colId = id ?? '';

    if (reportElementError[colId]) {
        const errorElement = reportElementError[colId].find(
            single => single.colName === colName
        );

        if (errorElement?.isPristine) return '';
        if (errorElement?.error) {
            return errorElement.colErrorMessage;
        }
    }

    return '';
};

export const readableErrorTerms = {
    segment_title: 'segment title',
    segment_overview_title: 'segment overview title',
    title: 'title'
};

export const getLengthErrorMessage = (key: string, length = 150) => {
    const readableTerm =
        readableErrorTerms?.[key as keyof typeof readableErrorTerms] ?? key;
    return `Max ${length} characters are allowed in ${readableTerm}`;
};

export const EDITOR_MAX_LIMIT_ERROR = "You've reached the max character limit.";
export const REQUIRE_FIELD_ERROR = 'This field is required';
export const EMPTY_TABLE_ERROR = 'Require at least one data to add';

/**
 * Moves an item from one list to another list.
 */
export const move = (
    source: string,
    destination: string,
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
) => {
    if (!destination) return {};

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {} as Record<string, string[]>;
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export const renderChartSvg = (type: string) => {
    switch (type) {
        case 'COLUMN':
            return columnchart;
        case 'TABLE':
            return tablechart;
        case 'GAUGE':
            return gaugechart;
        case 'LINE':
            return linechart;
        case 'DONUT':
            return piechart;
        case 'COUNT':
            return singlevaluechart;
        case 'segment_title':
            return segmentTitle;
        case 'segment_overview_title':
            return segmentOverviewTitle;
        case 'overview_text':
            return overviewText;
        case 'line_break':
            return segmentDivider;
        case 'page_break':
            return PageBreak;
        case 'table':
            return TableIcon;
        default:
            return '';
    }
};

export const makeReportFieldDirty = (
    reportElementError: ReportElementErrorType
): ReportElementErrorType =>
    mapValues(reportElementError, arr =>
        arr.map(item => omit(item, 'isPristine'))
    );

export const hasReportFieldError = (
    reportElementError: ReportElementErrorType
): boolean =>
    some(reportElementError, arr => arr.some(item => !has(item, 'isPristine')));
