import { Checkbox, FormLabel } from '@mui/material';
import { v4 as uuid } from 'uuid';

type CheckBoxSectionProps = {
    title: string;
    isChecked: boolean | undefined;
    handleChange: (value: boolean) => void;
};

const CheckBoxSection = ({
    title,
    isChecked,
    handleChange
}: CheckBoxSectionProps) => {
    const uniqueId = uuid();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '10pt'
            }}
        >
            <Checkbox
                checked={isChecked}
                id={`modal-field-input-check-section-${uniqueId}`}
                onChange={e => handleChange(e.target.checked)}
                style={{
                    transform: 'scale(0.8)'
                }}
                sx={{
                    width: '20px',
                    height: '20px',
                    marginRight: '5px'
                }}
            />
            <FormLabel htmlFor={`modal-field-input-check-section-${uniqueId}`}>
                {title}
            </FormLabel>
        </div>
    );
};

export default CheckBoxSection;
