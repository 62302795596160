import {
    Chips,
    Tooltip,
    Tags,
    StatusCount,
    InfoIcon,
    ErrorTri,
    WarningTri
} from '@armis/armis-ui-library';
import { TooltipProps } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import moment, { MomentInput } from 'moment';
import { GoInfo } from 'react-icons/go';
import { LOGIN, QUERY_PREFIX, SSO } from 'src/constants/APIConstants';
import {
    ACTIVE,
    AGGREGATED_VIEW_ALERTS_TOOLTIP_INFO,
    AGGREGATED_VIEW_COLLECTORS_TOOLTIP_INFO,
    AGGREGATED_VIEW_INTEGRATIONS_TOOLTIP_INFO,
    DEVICES,
    DONE,
    FAILED,
    INACTIVE,
    IN_PROGRESS,
    MODIFIED,
    MODIFIED_TEMPLATE_TOOLTIP,
    MODIFIED_TOOLTIP,
    POLICY,
    PUSH_TEMPLATE_COLUMN_INFO,
    PUSH_TEMPLATE_STATUS,
    PUSH_TEMPLATE_TOOLTIP_INFO,
    STATUS_TEXT,
    SUCCESS,
    SYNCED,
    TEMPLATE_PUSH_STATUS_TOOLTIP
} from 'src/constants/LabelText';
import {
    CREATE_REPORT,
    PUSH_HISTORY,
    REPORT_ROUTE,
    REPORTS,
    TEMPLATE_ROUTE,
    TENANT_MANAGEMENT
} from 'src/constants/RouteConstants';
import {
    activeCollectorRedirectLink,
    activeIntegrationRedirectLink,
    alertRedirectLink,
    collectorRedirectLink,
    deviceRedirectLink,
    draftIntegrationRedirectLink,
    entityMappingUrl,
    inActiveIntegrationRedirectLink,
    initializingCollectorRedirectLink,
    integrationRedirectLink,
    pendingActivationCollectorRedirectLink,
    policyRedirectLink,
    sitesRedirectLink,
    templateLinkedTenantAPIMapping,
    templatePolicyAPIMapping,
    templatePushSelectTenantAPIMapping
} from 'src/constants/TableConstants';
import { ThresoldStatus } from 'src/pages/containers/AggregatedView/ThresoldStatus/ThresoldStatus';
import { pushConflictStatus } from 'src/pages/containers/PolicyTemplate/constants';
import { StyledStatusContainer } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.style';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';
import { TooltipCompType } from 'src/types/CommonTypes';

import {
    StyledAnchorTag,
    StyledInfoContainer,
    StyledLink,
    StyledRow
} from './Common.style';

export const urlFilter = (url: string) => url.replace(/\/+$/g, '');

export const linkComp = (content: string, href: string) => (
    <StyledAnchorTag href={href} rel="noreferrer" target="_blank">
        {content}
    </StyledAnchorTag>
);

export const generateAnchorTag = (label: string, url: string) => (
    <StyledAnchorTag href={url} rel="noreferrer" target="_blank">
        {label}
    </StyledAnchorTag>
);

const formatActionType = (actionType: string): string =>
    actionType
        .split(/[ |_]/g)
        .map(chars => chars.charAt(0).toUpperCase() + chars.slice(1))
        .join(' ');

const renderTags = (items: any, showAsChip: boolean = true, maxTag = 2) => (
    <Tags
        color="default"
        items={items}
        showAsChip={showAsChip}
        showMaxTag={maxTag}
        size="small"
        variant="outlined"
    />
);

const renderChip = (label: string, color = 'success') => (
    <Chips color={color as any} label={label} size="small" variant="outlined" />
);

const renderModifiedTag = (tooltipMsg: string) => (
    <Tooltip
        arrow
        placement="bottom"
        sx={{
            '.MuiTooltip-tooltip': {
                marginTop: '4px !important'
            }
        }}
        title={<div className="tooltip-arrow-text">{tooltipMsg}</div>}
    >
        <Chips
            color="warning"
            deleteIcon={<GoInfo />}
            label={MODIFIED}
            onDelete={() => {}}
            size="small"
            variant="outlined"
        />
    </Tooltip>
);

const renderFailedReportTag = (tooltipMsg: string) => (
    <Tooltip
        arrow
        placement="bottom"
        sx={{
            '.MuiTooltip-tooltip': {
                marginTop: '4px !important'
            }
        }}
        title={<div className="tooltip-arrow-text">{tooltipMsg}</div>}
    >
        <Chips
            color="error"
            deleteIcon={<GoInfo />}
            label={FAILED}
            onDelete={() => {}}
            size="small"
            variant="outlined"
        />
    </Tooltip>
);

export const MasterColumnsConfig: ColDef[] = [
    {
        headerName: '',
        resizable: false,
        suppressMovable: true,
        suppressSizeToFit: true,
        type: '',
        headerClass: 'no-border-right cell-small',
        checkboxSelection: true,
        field: 'checkbox',
        headerCheckboxSelection: true,
        maxWidth: 40,
        cellClass: ['cell-small']
    },
    {
        headerName: '',
        resizable: false,
        suppressMovable: true,
        suppressSizeToFit: true,
        type: '',
        field: 'masterDetail',
        cellRenderer: 'agGroupCellRenderer',
        maxWidth: 40,
        headerClass: 'cell-small',
        cellClass: ['cell-small']
    }
];

const aggregatedTooltipComp = (infoArray: string[], textReplace: string) => (
    <StyledInfoContainer>
        {infoArray.map((text, index) => (
            <StyledRow key={index} className="row">
                <div className="left" style={{ minWidth: 'unset' }}>
                    {index === 0 ? <ErrorTri /> : <WarningTri />}
                </div>
                <div className="right">{text.replace('%s', textReplace)}</div>
            </StyledRow>
        ))}
    </StyledInfoContainer>
);

export const reportHistoryColumnsConfig: ColDef[] = [
    // ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'title',
        headerName: 'Title',
        cellRenderer: (params: ICellRendererParams) =>
            params?.data?.reportConfig?.title
    },
    {
        field: 'name',
        headerName: 'Template Used',
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink
                to={`${TEMPLATE_ROUTE}/${REPORT_ROUTE}/${CREATE_REPORT}`}
            >
                {params?.data?.reportConfig?.name}
            </StyledLink>
        )
    },
    {
        field: 'tenantName',
        headerName: 'Tenant',
        cellRenderer: (params: ICellRendererParams) =>
            params?.data?.tenant?.name
    },
    {
        field: 'createdby',
        headerName: 'Ran By',
        filter: true
    },
    {
        field: 'createddate',
        headerName: 'Date',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A'),
        filter: true,
        filterParams: {
            type: 'date'
        }
    },
    {
        field: 'outputFormat',
        headerName: 'Format',
        sortable: false
    },
    {
        field: 'status',
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) =>
            params.value === 'FAILED'
                ? renderFailedReportTag(params.data.message.message)
                : renderChip(
                      params.value.slice(0, 1) +
                          params.value.slice(1).toLowerCase(),
                      params.value === 'COMPLETED'
                          ? 'success'
                          : params.value === 'GENERATING'
                          ? 'info'
                          : 'error'
                  ),
        filter: true
    }
];

export const reportHistoryColumnsConfigByTenant: ColDef[] = [
    // ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'title',
        headerName: 'Title',
        cellRenderer: (params: ICellRendererParams) =>
            params?.data?.reportConfig?.title
    },
    {
        field: 'name',
        headerName: 'Template Used',
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink
                to={`${TEMPLATE_ROUTE}/${REPORT_ROUTE}/${CREATE_REPORT}`}
            >
                {params?.data?.reportConfig?.name}
            </StyledLink>
        )
    },
    {
        field: 'createdby',
        headerName: 'Ran By',
        filter: true
    },
    {
        field: 'createddate',
        headerName: 'Date',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A'),
        filter: true,
        filterParams: {
            type: 'date'
        }
    },
    {
        field: 'outputFormat',
        headerName: 'Format',
        sortable: false
    },
    {
        field: 'status',
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) =>
            params.value === 'FAILED'
                ? renderFailedReportTag(params.data.message.message)
                : renderChip(
                      params.value.slice(0, 1) +
                          params.value.slice(1).toLowerCase(),
                      params.value === 'COMPLETED'
                          ? 'success'
                          : params.value === 'GENERATING'
                          ? 'info'
                          : 'error'
                  ),
        filter: true
    }
];

export const reportHistoryColumnsConfigByTenantByReport: ColDef[] = [
    // ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'title',
        headerName: 'Title',
        cellRenderer: (params: ICellRendererParams) =>
            params?.data?.reportConfig?.title
    },

    {
        field: 'createdby',
        headerName: 'Ran By',
        filter: true
    },
    {
        field: 'createddate',
        headerName: 'Date',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A'),
        filter: true,
        filterParams: {
            type: 'date'
        }
    },
    {
        field: 'outputFormat',
        headerName: 'Format',
        sortable: false
    },
    {
        field: 'status',
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) =>
            params.value === 'FAILED'
                ? renderFailedReportTag(params.data.message.message)
                : renderChip(
                      params.value.slice(0, 1) +
                          params.value.slice(1).toLowerCase(),
                      params.value === 'COMPLETED'
                          ? 'success'
                          : params.value === 'GENERATING'
                          ? 'info'
                          : 'error'
                  ),
        filter: true
    }
];

export const tenantColumnsConfig: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'name',
        headerName: 'Name'
    },
    {
        field: 'totalPolicies',
        headerName: 'No. of Policies',
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink to={`${params.data.id}#policy`}>
                {params.value}
            </StyledLink>
        )
    },
    {
        field: 'totalRunReports',
        headerName: 'No. of Reports',
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink to={`${params.data.id}#report`}>
                {params.value}
            </StyledLink>
        )
    },
    {
        field: 'lastSynced',
        headerName: 'Last Sync Date',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A')
    },
    {
        field: 'tenantUrl',
        headerName: 'URL',
        cellRenderer: (params: ICellRendererParams) =>
            generateAnchorTag(
                params.value,
                `${urlFilter(params.value)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            ),
        sortable: false
    }
];

export const commonDashboardColumnDef: ColDef[] = [
    {
        field: 'name',
        headerName: 'Tenant Name',
        minWidth: 120,
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.name,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            )
    },
    {
        field: 'results',
        headerName: '# Of Results',
        minWidth: 130,
        cellRenderer: (params: ICellRendererParams) => {
            const asqSearch: string = params.data.asq;
            const partsAsq = asqSearch.split(' ');
            const entity = partsAsq[0].split(':')?.[1] ?? DEVICES.toLowerCase();
            const appendUrl =
                (entityMappingUrl[entity as keyof typeof entityMappingUrl] ??
                    entityMappingUrl.devices) +
                encodeURIComponent(asqSearch).replaceAll('%20', '+');
            return params.data.message ? (
                <>
                    <span style={{ textAlign: 'center' }}> — </span>
                    <Tooltip
                        arrow
                        placement="right"
                        title={
                            <div className="tooltip-arrow-text">
                                {params.data.message}
                            </div>
                        }
                    >
                        <Chips
                            color="error"
                            deleteIcon={
                                <InfoIcon style={{ fill: '#e74c3c' }} />
                            }
                            label={FAILED}
                            onDelete={() => {}}
                            size="small"
                            sx={{
                                marginLeft: '15px'
                            }}
                            variant="outlined"
                        />
                    </Tooltip>
                </>
            ) : (
                linkComp(
                    params.data.results,
                    `${urlFilter(params.data.tenantUrl)}${
                        params.context.isArmisUser
                            ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                            : ''
                    }${appendUrl}`
                )
            );
        }
    }
];

export const aggregatedColumnDef = [
    {
        field: 'tenantName',
        headerName: 'Tenant Name',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.tenantName,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            ),
        headerClass: 'header-no-top-border'
    },
    {
        field: 'device',
        headerName: 'Devices',
        headerClass: 'header-no-top-border',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.device,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${deviceRedirectLink}${encodeURIComponent(
                    params.context.DEVICES_ASQ
                ).replaceAll('%20', '+')}`
            )
    },
    {
        field: 'site',
        headerName: 'Sites',
        headerClass: 'header-no-top-border',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.site,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${sitesRedirectLink}`
            )
    },
    {
        field: 'alert',
        headerName: 'Alerts',
        cellRenderer: (params: ICellRendererParams) => (
            <ThresoldStatus
                actualValue={params.data.alert}
                redirectLink={`${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${alertRedirectLink}${encodeURIComponent(
                    params.context.ALERT_ASQ
                ).replaceAll('%20', '+')}`}
                thresoldValue={params.context.ALERT_THRESHOLD}
            />
        ),
        headerClass: 'header-no-top-border',
        tooltipComponent: (props: TooltipCompType) =>
            aggregatedTooltipComp(
                AGGREGATED_VIEW_ALERTS_TOOLTIP_INFO,
                props.context.ALERT_THRESHOLD
            )
    },
    {
        field: 'activePolicy',
        headerName: 'Active Policies',
        headerClass: 'header-no-top-border',
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                params.data.activePolicy,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser
                        ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                        : ''
                }${policyRedirectLink}`
            )
    },
    {
        field: 'Integrations',
        headerClass: 'row-separator',
        children: [
            {
                field: 'activeIntegration',
                headerName: 'Active',

                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.activeIntegration,
                        `${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${activeIntegrationRedirectLink}`
                    )
            },
            {
                field: 'inactiveIntegration',
                headerName: 'Inactive',

                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.inactiveIntegration,
                        `${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${inActiveIntegrationRedirectLink}`
                    )
            },
            {
                field: 'draftIntegration',
                headerName: 'Draft',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.draftIntegration,
                        `${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${draftIntegrationRedirectLink}`
                    )
            },
            {
                field: 'errorIntegration',
                headerName: 'Error',
                cellRenderer: (params: ICellRendererParams) => (
                    <ThresoldStatus
                        actualValue={params.data.errorIntegration}
                        redirectLink={`${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${integrationRedirectLink}`}
                        thresoldValue={
                            params.context.INTEGRATION_ERROR_THRESHOLD
                        }
                    />
                ),
                tooltipComponent: (props: TooltipCompType) =>
                    aggregatedTooltipComp(
                        AGGREGATED_VIEW_INTEGRATIONS_TOOLTIP_INFO,
                        props.context.INTEGRATION_ERROR_THRESHOLD
                    )
            }
        ]
    },
    {
        field: 'Collectors',
        headerClass: 'row-separator',
        children: [
            {
                field: 'activeCollector',
                headerName: 'Active',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.activeCollector,
                        `${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${activeCollectorRedirectLink}`
                    )
            },
            {
                field: 'offlineCollector',
                headerName: 'Offline',
                cellRenderer: (params: ICellRendererParams) => (
                    <ThresoldStatus
                        actualValue={params.data.offlineCollector}
                        redirectLink={`${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${collectorRedirectLink}`}
                        thresoldValue={
                            params.context.COLLECTORS_OFFLINE_THRESHOLD
                        }
                    />
                ),
                tooltipComponent: (props: TooltipCompType) =>
                    aggregatedTooltipComp(
                        AGGREGATED_VIEW_COLLECTORS_TOOLTIP_INFO,
                        props.context.COLLECTORS_OFFLINE_THRESHOLD
                    )
            },
            {
                field: 'initializingCollector',
                headerName: 'Initializing',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.initializingCollector,
                        `${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${initializingCollectorRedirectLink}`
                    )
            },
            {
                field: 'pendingActivationCollector',
                headerName: 'Pending Activation',
                cellRenderer: (params: ICellRendererParams) =>
                    linkComp(
                        params.data.pendingActivationCollector,
                        `${urlFilter(params.data.tenantUrl)}${
                            params.context.isArmisUser
                                ? `${LOGIN}${SSO}${QUERY_PREFIX}`
                                : ''
                        }${pendingActivationCollectorRedirectLink}`
                    )
            }
        ]
    },
    {
        field: 'tenantUrl',
        headerName: 'Tenant URL',
        sortable: false,
        cellRenderer: (params: ICellRendererParams) =>
            linkComp(
                urlFilter(params.data.tenantUrl),
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            ),
        headerClass: 'header-no-top-border'
    }
];

export const tenantPolicyColumnsConfig: ColDef[] = [
    {
        field: 'name',
        headerName: 'Title',
        width: 400
    },
    {
        field: 'actionType',
        headerName: 'Action Type',
        cellStyle: {
            padding: '10px'
        },
        cellRenderer: ({
            data: { tenantPolicyActionTypes }
        }: ICellRendererParams) =>
            renderTags(
                tenantPolicyActionTypes.map((actions: any) => ({
                    id: actions.id,
                    label: formatActionType(actions.actionType)
                })),
                false,
                1
            ),
        sortable: false
    },
    {
        field: 'enabled',
        headerName: 'Status',
        cellStyle: {
            textAlign: 'center'
        },
        cellRenderer: ({ data: { enabled } }: ICellRendererParams) =>
            renderChip(
                enabled ? ACTIVE : INACTIVE,
                enabled ? 'success' : 'secondary'
            )
    },
    {
        field: 'tags',
        headerName: 'Tags',
        cellStyle: {
            padding: '12px 5px 0px 5px'
        },
        cellRenderer: (params: ICellRendererParams) => {
            if (params.value?.length) {
                return renderTags(
                    params.value.map((tag: string) => ({
                        id: tag,
                        label: tag
                    }))
                );
            }
            return '';
        },
        sortable: false,
        filter: true
    },
    {
        field: 'linkedTemplateStatus',
        headerName: 'Linked Template Status',
        cellStyle: {
            textAlign: 'center'
        },
        cellRenderer: (params: ICellRendererParams<Policy>) => {
            if (params.data?.isModified) {
                return renderModifiedTag(MODIFIED_TOOLTIP);
            }
            if (params.data?.tags?.length) {
                return renderChip(SYNCED);
            }
            return '';
        },
        sortable: false
    }
];

export const templatePolicyColumnConfig: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    ...tenantPolicyColumnsConfig.filter(colDef =>
        Object.keys(templatePolicyAPIMapping).includes(colDef.field || '')
    ),
    {
        field: 'tenantCount',
        headerName: 'Tenants',
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink to={params.data.id}>{params.value}</StyledLink>
        ),
        sortable: false,
        width: 100
    },
    {
        field: 'policyTemplateActionType',
        headerName: 'Action Type',
        cellStyle: {
            padding: '10px'
        },
        cellRenderer: ({
            data: { policyTemplateActionType }
        }: ICellRendererParams) =>
            renderTags(
                policyTemplateActionType.map((actions: any) => ({
                    id: actions.id,
                    label: formatActionType(actions.actionType)
                })),
                false,
                1
            ),
        sortable: false
    },
    {
        field: 'pushJobPolicyTemplate',
        headerName: 'Last Pushed Status',
        cellStyle: {
            padding: '0 17px'
        },
        cellRenderer: ({ data }: ICellRendererParams<Policy>) =>
            data?.pushJobPolicyTemplate && (
                <StyledStatusContainer>
                    <StatusCount
                        inProgress={
                            data?.pushJobPolicyTemplate.pushJobPolicy.status ===
                            'IN_PROGRESS'
                        }
                        size={36}
                        success={data?.pushJobPolicyTemplate.success}
                        total={data?.pushJobPolicyTemplate.total}
                    />
                    <StyledLink
                        to={`${TEMPLATE_ROUTE}/${PUSH_HISTORY}/${data.pushJobPolicyTemplate.pushJobPolicy.id}`}
                    >
                        {STATUS_TEXT}
                    </StyledLink>
                </StyledStatusContainer>
            ),
        sortable: false,
        tooltipComponent: (
            <>
                <StyledInfoContainer>
                    {PUSH_TEMPLATE_COLUMN_INFO.map((text, index) => (
                        <StyledRow key={index} className="row">
                            <div className="left">
                                {index !== 0 ? (
                                    <StatusCount
                                        showTooltip={false}
                                        size={36}
                                        success={text.success}
                                        total={text.total}
                                    />
                                ) : (
                                    <div
                                        className="right"
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 'bolder',
                                            paddingLeft: '0px'
                                        }}
                                    >
                                        {STATUS_TEXT}
                                    </div>
                                )}
                            </div>
                            <div className="right">{text.info}</div>
                        </StyledRow>
                    ))}
                </StyledInfoContainer>
                <div />
            </>
        )
    },
    {
        field: 'lastPushed',
        headerName: 'Last Pushed',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A')
    },
    {
        field: 'createdby',
        headerName: 'Created By'
    },
    {
        field: 'updateddate',
        headerName: 'Last Modified',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A')
    }
];

export const templateLinkedTenantConfig: ColDef[] = [
    ...tenantColumnsConfig.filter(colDef =>
        Object.keys(templateLinkedTenantAPIMapping).includes(colDef.field || '')
    ),
    {
        field: 'modified',
        headerName: 'Template Status',
        cellRenderer: (params: ICellRendererParams) =>
            params.value
                ? renderModifiedTag(MODIFIED_TEMPLATE_TOOLTIP)
                : renderChip(SYNCED),
        sortable: false
    }
];

export const reportTemplateLinkedTenantConfig: ColDef[] = [
    ...tenantColumnsConfig.filter(colDef =>
        Object.keys(templateLinkedTenantAPIMapping).includes(colDef.field || '')
    )
];

export const templatePushSelectTenantColumnConfig: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    ...tenantColumnsConfig.filter(colDef =>
        Object.keys(templatePushSelectTenantAPIMapping).includes(
            colDef.field || ''
        )
    )
];

export const templateValidatePushColumnConfig: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'templateName',
        headerName: 'Template Name',
        width: 200
    },
    {
        field: 'conflictStatus',
        headerName: 'Conflict Status',
        minWidth: 150,
        cellRenderer: (params: ICellRendererParams) =>
            renderChip(
                pushConflictStatus[params.value].label,
                pushConflictStatus[params.value].color
            ),
        tooltipComponent: (
            <>
                <StyledInfoContainer>
                    {Object.keys(pushConflictStatus).map(status => (
                        <StyledRow key={status} className="row">
                            <div className="left">
                                {renderChip(
                                    pushConflictStatus[status].label,
                                    pushConflictStatus[status].color
                                )}
                            </div>
                            <div className="right">
                                {pushConflictStatus[status].description}
                            </div>
                        </StyledRow>
                    ))}
                </StyledInfoContainer>
                <div />
            </>
        ),
        tooltipComponentParams: {
            componentsProps: {
                popper: {
                    sx: {
                        zIndex: '10002 !important'
                    }
                }
            }
        } as TooltipProps
    },
    {
        field: 'tags',
        headerName: 'Tags',
        cellStyle: {
            padding: '12px 5px 0px 5px'
        },
        cellRenderer: (params: ICellRendererParams) => {
            if (params.value?.length) {
                return renderTags(
                    params.value.map((tag: string) => ({
                        id: tag,
                        label: tag
                    }))
                );
            }
            return '';
        }
    }
];

export const reportColumnsConfig: ColDef[] = [
    {
        field: 'reportName',
        headerName: 'Name'
    },
    {
        field: 'createdBy',
        headerName: 'Created By'
    },
    {
        field: 'createdDate',
        headerName: 'Created Date'
    },
    {
        field: 'tags',
        headerName: 'Tags'
    }
];

export const auditLogsColumnDef: ColDef[] = [
    {
        field: 'createdon',
        headerName: 'Time',
        cellRenderer: (params: ICellRendererParams) =>
            moment(params.data.createdon as MomentInput).format(
                'MMM D, YYYY h:mm:ss A'
            ),
        sortable: true,
        filter: true,
        filterParams: {
            type: 'date'
        }
    },
    {
        field: 'actiontype',
        headerName: 'Trigger'
    },
    {
        field: 'action',
        headerName: 'Action',
        filter: true
    },
    {
        field: 'username',
        headerName: 'User',
        filter: true
    },
    {
        field: 'userip',
        headerName: 'User IP'
    },
    {
        field: 'info',
        headerName: 'Info'
    }
];

export const userManagementDef: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'firstname',
        headerName: 'Name',
        cellRenderer: (params: ICellRendererParams) =>
            `${params.data.firstname} ${params.data.lastname}`,
        sortable: true
    },
    {
        field: 'username',
        headerName: 'Email',
        sortable: true
    },
    {
        field: 'roles',
        headerName: 'Roles',
        cellRenderer: (params: ICellRendererParams) =>
            params.data.roles.map((item: any) => item.name).join(', '),
        sortable: false
    },
    {
        field: 'lastloggedin',
        headerName: 'Last Login Time',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.data.lastloggedin &&
            moment(params.data.lastloggedin as MomentInput).format(
                'MMM D, YYYY hh:mm A'
            ),
        sortable: true
    }
];

export const pushHistoryColumnsDef: ColDef[] = [
    {
        field: 'jobId',
        headerName: 'Push ID'
    },
    {
        field: 'createdby',
        headerName: 'Pushed By',
        filter: true
    },
    {
        field: 'createddate',
        headerName: 'Time',
        cellRenderer: (params: ICellRendererParams) =>
            moment(params.data.createddate as MomentInput).format(
                'MMM D, YYYY hh:mm A'
            )
    },
    {
        field: 'type',
        headerName: 'Type',
        cellRenderer: () => POLICY,
        sortable: false
    },
    {
        field: 'status',
        headerName: 'Status',
        filter: true,
        cellStyle: {
            textAlign: 'center'
        },
        cellRenderer: ({ data: { status } }: ICellRendererParams) =>
            status === 'DONE'
                ? renderChip(DONE)
                : renderChip(IN_PROGRESS, 'secondary')
    }
];

export const pushHistoryDetailedColumnsDef: ColDef[] = [
    {
        field: 'name',
        headerName: 'Tenant Name',
        cellRenderer: (params: ICellRendererParams) =>
            generateAnchorTag(
                params.data.name,
                `${urlFilter(params.data.tenantUrl)}${
                    params.context.isArmisUser ? `${LOGIN}${SSO}` : ''
                }`
            )
    },
    {
        field: 'status',
        headerName: 'Template Push Status',
        cellRenderer: (params: ICellRendererParams) =>
            params.data && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}
                >
                    <StyledStatusContainer>
                        <StatusCount
                            size={36}
                            success={params.data?.success}
                            tooltipMessage={TEMPLATE_PUSH_STATUS_TOOLTIP.replace(
                                '%s',
                                params.data?.success
                            ).replace('%t', params.data?.total)}
                            total={params.data?.total}
                        />
                    </StyledStatusContainer>
                </div>
            ),
        sortable: false,
        tooltipComponent: (
            <>
                <StyledInfoContainer>
                    {PUSH_TEMPLATE_TOOLTIP_INFO.map((text, index) => (
                        <StyledRow key={index} className="row">
                            <div className="left">
                                <StatusCount
                                    showTooltip={false}
                                    size={36}
                                    success={text.success}
                                    total={text.total}
                                />
                            </div>
                            <div className="right">{text.info}</div>
                        </StyledRow>
                    ))}
                </StyledInfoContainer>
                <div />
            </>
        )
    }
];

export const pushHistoryTemplateDetailedColumnsDef: ColDef[] = [
    {
        field: 'templateName',
        headerName: 'Name',
        cellRenderer: (params: ICellRendererParams) =>
            `${params.data.pushJobPolicyTemplate.templateName}`,
        sortable: true
    },
    {
        field: 'status',
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) =>
            params.data.status === 'SUCCESS' ? (
                renderChip(SUCCESS)
            ) : (
                <Tooltip
                    arrow
                    placement="right"
                    title={
                        <div className="tooltip-arrow-text">
                            {params.data.message.message}
                        </div>
                    }
                >
                    <Chips
                        color="error"
                        deleteIcon={<InfoIcon style={{ fill: '#e74c3c' }} />}
                        label={FAILED}
                        onDelete={() => {}}
                        size="small"
                        variant="outlined"
                    />
                </Tooltip>
            ),
        sortable: false,
        tooltipComponent: (
            <>
                <StyledInfoContainer>
                    {PUSH_TEMPLATE_STATUS.map((text, index) => (
                        <StyledRow key={index} className="row">
                            <div className="left">
                                <Chips
                                    color={
                                        text.status === 'SUCCESS'
                                            ? 'success'
                                            : 'error'
                                    }
                                    deleteIcon={
                                        text.status === 'FAILED' ? (
                                            <InfoIcon
                                                style={{ fill: '#e74c3c' }}
                                            />
                                        ) : undefined
                                    }
                                    label={
                                        text.status === 'FAILED'
                                            ? FAILED
                                            : SUCCESS
                                    }
                                    onDelete={
                                        text.status === 'FAILED'
                                            ? () => {}
                                            : undefined
                                    }
                                    size="small"
                                    variant="outlined"
                                />
                            </div>
                            <div className="right">{text.info}</div>
                        </StyledRow>
                    ))}
                </StyledInfoContainer>
                <div />
            </>
        )
    }
];

export const reportTemplateColumnsConfig: ColDef[] = [
    ...MasterColumnsConfig.slice(0, 1),
    {
        field: 'name',
        headerName: 'Name'
    },
    {
        field: 'tags',
        headerName: 'Tags',
        cellStyle: {
            padding: '12px 5px 0px 5px'
        },
        cellRenderer: (params: ICellRendererParams) => {
            if (params.value?.length) {
                return renderTags(
                    params.value.map((tag: string) => ({
                        id: tag,
                        label: tag
                    }))
                );
            }
            return '';
        },
        sortable: false,
        filter: true
    },
    {
        field: 'tenantCount',
        headerName: 'Tenants',
        sortable: false,
        cellRenderer: (params: ICellRendererParams) => (
            <StyledLink
                to={`${TEMPLATE_ROUTE}/${REPORTS}/${params.data.id}${TENANT_MANAGEMENT}`}
            >
                {params.value}
            </StyledLink>
        )
    },
    {
        field: 'lastRun',
        headerName: 'Last Run',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A'),
        sortable: false
    },
    {
        field: 'createdby',
        headerName: 'Created By'
    },
    {
        field: 'updateddate',
        headerName: 'Last Modified',
        cellRenderer: (params: ICellRendererParams) =>
            !!params.value &&
            moment(params.value as MomentInput).format('MMM D, YYYY HH:mm A')
    }
];
